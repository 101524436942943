<template>
  <div class="gatewayList">
    <div class="head">
      <m-input-search
          type="text"
          class="input-search"
          v-model="search"
          placeholder="名称或编码"
          @pressEnter="httpGatewayList"
          @blur="httpGatewayList">
        <img slot="prefix" alt="text" class="input-img" src="../../../assets/imgs/sous_icon@2x.png" @click="httpGatewayList" />
      </m-input-search>
      <m-select-transparency
          class="select"
          dropdownClassName="m-select-dropdown"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          v-model="group_id"
          @change="handleChange"
      >
        <a-select-option value="">全部</a-select-option>
        <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.id">{{ item.name }}</a-select-option>
      </m-select-transparency>
    </div>
    <div class="striping"></div>
    <div class="list">
      <div v-if="gatewayForm.length > 0">
        <template>
          <a-list
              item-layout="horizontal"
              :split="false"
              :data-source="gatewayForm"
              :pagination="false"
          >
            <a-list-item
                slot="renderItem"
                slot-scope="item, index"
                :class="{highlight: itemIndex === index}"
                @click="handleItem(item,index)"
            >
              <a-list-item-meta :description="'地址：' + item.id">
                <a slot="title">{{ item.name }}</a>
                <div slot="avatar" style="position: relative;">
                  <img alt="text" class="img" src="../../../assets/imgs/wangg_img@2x.png" />
                  <div :class="item.state=== 1 ?'online':'offline'">{{ item.state=== 1 ?'在线':'离线' }}</div>
                </div>
              </a-list-item-meta>
            </a-list-item>
          </a-list>
          <m-pagination :hideOnSinglePage="true" :defaultCurrent="defaultCurrent" :pageSize="pageSize" :total="total" @change="onChange"></m-pagination>
        </template>
      </div>
      <div v-else>
        <empty-content>
          <template slot="img">
            <img class="listImage" alt="text" src="../../../assets/imgs/wusj_img@2x.png" />
          </template>
          <template slot="desc">
            <span>暂无网关</span>
          </template>
        </empty-content>
      </div>
    </div>
  </div>
</template>

<script>
  import { EventBus } from "@/utils/event-bus";
  import { mapActions, mapState } from "vuex";
  export default {
    name: "gateway-list",
    data() {
      return {
        group_id: '',
        search: '',
        groupForm: [],
        gatewayForm:[],
        itemIndex: 0,
        defaultCurrent: 1,
        pageSize: 10,
        total: 0,
        isFind: false
      }
    },
    props: {
      idGroup: String,
      idGateway: String,
      required: true,
    },
    created() {
      this.$store.commit('gatewayManagement/updateState', {gateway_id: ''})
      this.$store.commit('gatewayManagement/updateState', {gateway_name: ''})
      this.$store.commit('gatewayManagement/updateState', {gateway_state: ''})
      this.$store.commit('gatewayManagement/updateState', {group_name: ''} )
      this.$store.commit('gatewayManagement/updateState', {group_id: ''} )
      this.$store.commit('gatewayManagement/updateState', {sensor_id: ''})
      this.$store.commit('gatewayManagement/updateState', {sensor_show_id: ''})
      this.$store.commit('gatewayManagement/updateState', {sensor_name: ''})
      this.$store.commit('gatewayManagement/updateState', {sensor_state: ''})
      let payload = {
        group_id: this.idGroup ? this.idGroup : '',
        search: this.search,
        limit: this.pageSize,
        offset: (this.defaultCurrent - 1) * this.pageSize
      }
      this.group_id = this.idGroup ? parseInt(this.idGroup) : ''
      this.getGatewayList(payload);
      this.group_id = this.idGroup ? parseInt(this.idGroup) : ''
    },
    mounted() {
      EventBus.$on('updateGatewayList',  () =>{
        this.group_id = ''
        this.httpGatewayList()
      })
    },
    computed: {
      ...mapState('gatewayManagement',['gatewayList']),
      ...mapState('groupManagement', ['groupList'])
    },
    watch: {
      'gatewayList': function (newValue){
        this.gatewayForm = newValue.list
        this.total = newValue.total
        let flag = 0
        if(this.idGateway && !this.isFind){
          for(let i=0; i< this.gatewayForm.length;i++){
            if(this.gatewayForm[i].id === this.idGateway ){
              flag = 1
              this.handleItem(this.gatewayForm[i], i)
              this.isFind = true
            }
          }
          if (flag === 0) {
            this.defaultCurrent += 1
            this.findGateway(this.idGateway, this.defaultCurrent)
            return
          }
          this.$store.commit('gatewayManagement/updateState', {gateway_id: this.idGateway})
        }else if (this.gatewayForm.length){
          this.handleItem(this.gatewayForm[0],0)
        }else {
          this.$store.commit('gatewayManagement/updateState', {gateway_id: ''})
          this.$store.commit('gatewayManagement/updateState', {gateway_name: ''})
          this.$store.commit('gatewayManagement/updateState', {gateway_state: ''})
          this.$store.commit('gatewayManagement/updateState', {group_name: ''} )
          this.$store.commit('gatewayManagement/updateState', {group_id: ''} )
        }
      },
    },
    methods: {
      ...mapActions('gatewayManagement', ['getGatewayList']),
      ...mapActions('groupManagement', [ 'getGroupList' ]),
      handleChange(id){
        this.$store.commit('gatewayManagement/updateState', {select_id: id})
        this.httpGatewayList()
      },
      httpGatewayList(){
        let payload = {
          group_id: this.group_id,
          search: this.search,
          limit: this.pageSize,
          offset: (this.defaultCurrent - 1) * this.pageSize
        }
        this.getGatewayList(payload)
      },
      findGateway(gateway_id, page) {
        let total_page = (this.total % this.pageSize === 0) ? this.total / this.pageSize : (parseInt(this.total / this.pageSize) + 1)
        if (page > total_page) {
          return;
        }
        let payload = {
          group_id: this.idGroup,
          limit: this.pageSize,
          offset: (page-1) * this.pageSize
        }
        this.getGatewayList(payload)
      },
      handleItem(item,index){
        this.itemIndex = index
        this.$store.commit('gatewayManagement/updateState', {gateway_id: item.id})
        this.$store.commit('gatewayManagement/updateState', {gateway_name: item.name})
        this.$store.commit('gatewayManagement/updateState', {gateway_state: item.state})
        this.$store.commit('gatewayManagement/updateState', {group_name: item.group_name})
        this.$store.commit('gatewayManagement/updateState', {group_id: item.group_id} )
      },
      onChange(defaultCurrent) {
        this.defaultCurrent = defaultCurrent;
        this.httpGatewayList()
      }
    },
  }
</script>

<style lang="less">
  .gatewayList {
    height: 100%;

    .head {
      position: relative;
      display: flex;
      justify-content: space-between;
      padding: 4px 0 4px 0;
      height: 40px;
    }

    .ant-input {
      padding: 3px 11px 4px 31px!important;
    }

    .input-search {
      color: #658C99;
    }

    @media (min-width: 0px){
      .input-search {
        width: 148px;
      }
    }

    @media (min-width: 1600px){
      .input-search {
        width: 190px;
      }
    }

    .select {
      flex: 1;
      min-width: 100px;
    }


    .ant-select-selection-selected-value {
      margin-right: 8px;
      float:right;
    }

    .input-img {
      width: 14px;
      height: 14px;
      cursor: pointer;
      margin-bottom: 2px;
    }

    .list {
      padding: 6px 0 0 0;
      height: 718px;
    }

    .ant-list-items {
      height: 661px;
    }

    .ant-list-item-meta:hover {
      margin-left: 0.5px !important;
    }

    .highlight {
      .ant-list-item-meta {
        background: #153B42;
        margin-left: 0.5px;
      }
    }

    .listImage {
      margin-top: 234px;
      width: 140px;
      height: 140px;
      fill: #658C99;
      overflow: hidden;
    }
  }

  .backgroundImage {
    margin-top: 281px;
    width: 140px;
    height: 140px;
    fill: #658C99;
    overflow: hidden;
  }

  .striping {
    width: 100%;
    height: 1px;
    background-color: #225461;
  }

  .m-select-dropdown{
    min-width: 170px;
    left: unset!important;
    right: 0!important;
  }
</style>