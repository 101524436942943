<template>
  <div class="hum-particulars">
    <div v-if="sensor_id !== ''">
      <m-form>
        <div class="head">
          <a-space :size="8">
            <div :class="sensor_state === 1 ? 'stateOn':'stateOff'">{{ sensor_state === 1 ? '在线':'离线'}}</div>
            <span class="name">{{ sensor_name }}</span>
          </a-space>
          <div class="dropdown">
            <a-dropdown :placement="placement" overlayClassName="overlayClass" :open="true">
              <a @click="e => e.preventDefault()">
                <img alt="text" class="dropdown-img" src="../../../assets/imgs/gengd_icon@2x.png" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item key="sensorEdit" @click="handleEdit(sensor_id,sensor_name)">
                  <a>修改名称</a>
                </a-menu-item>
                <a-menu-item key="sensorAlarm" @click="handleWarning(sensor_id,sensor_name)">
                  <a>告警通知</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </div>
        <div class="news">
          <div class="text">地址:</div>
          <span class="data" :title="sensor_id">{{ sensor_show_id }}</span>
          <div class="circle"></div>
          <span class="text">当前温度:</span>
          <span class="data2">{{ temp }}℃</span>
          <div class="circle"></div>
          <span class="text">当前湿度:</span>
          <span class="data2">{{ hum }}%</span>
          <div class="circle"></div>
          <span class="text">最后更新时间:</span>
          <span class="data" :title="time">{{ time }}</span>
        </div>
        <div class="striping"></div>
        <div class="stern">
          <div class="date">
            <m-date-picker style="width: 178px;" v-model="date" @change="handleChange"></m-date-picker>
          </div>
          <m-table
            tableLayout="fixed"
            :columns="columns"
            :data-source="tableData"
            :expandIcon="() => null"
            :expandedRowKeys.sync="expandedRowKeys"
            :pagination="false"
            :scroll="{y: 380}"
            :customRow="customRow"
          >
            <span slot="temp" style="cursor: pointer" slot-scope="{ text,record,}" @click="handleTemp(record)">
              {{ record.min_temp }}℃ - {{ record.max_temp }}℃
              <img alt="text" :class="record.clickTemp ? 'lastImg' : 'img'" src="../../../assets/imgs/xial_icon@2x.png" />
            </span>
            <span slot="hum" style="cursor: pointer" slot-scope="{ text,record}" @click="handleHum(record)">
              {{ record.min_hum }}% - {{ record.max_hum}}%
              <img alt="text" :class="record.clickHum ? 'lastImg' : 'img'" src="../../../assets/imgs/xial_icon@2x.png" />
            </span>
            <template slot="expandedRowRender" slot-scope="{record,index}">
              <model-temp-chart v-if="showTempChart" :id="'chart-temp'+index" :item="chartData" ></model-temp-chart>
              <model-hum-chart v-if="showHumChart" :id="'chart-hum'+index" :item="chartData"></model-hum-chart>
            </template>
          </m-table>
          <m-pagination style="margin-top: 10px" :hideOnSinglePage="true" :defaultCurrent="defaultCurrent" :pageSize="pageSize" :total="total" @change="onChange"></m-pagination>
        </div>
      </m-form>
    </div>
    <div v-else>
      <empty-content>
        <template slot="img">
          <img class="backgroundImage" alt="text" src="../../../assets/imgs/wusj_img@2x.png" />
        </template>
        <template slot="desc">
          <span>暂无数据</span>
        </template>
      </empty-content>
    </div>
    <model-sensor-edit v-model="showEdit" :item="item"></model-sensor-edit>
    <drawer-warning v-model="showWarning"></drawer-warning>
  </div>
</template>

<script>
  import moment from 'moment'
  import { mapActions, mapState } from "vuex";
  import ModelSensorEdit from "@/components/Common/ModelSensorEdit";
  import ModelTempChart from "@/components/Common/ModelTempChart";
  import ModelHumChart from "@/components/Common/ModelHumChart";
  import DrawerWarning from "@/components/Common/DrawerWarning.vue";
  export default {
    name: "hum-particulars",
    components: {DrawerWarning, ModelHumChart, ModelTempChart, ModelSensorEdit},
    data() {
      return {
        temp: "",
        hum: "",
        time: "",
        placement: 'bottomRight',
        showEdit: false,
        showWarning: false,
        item: null,
        date: null,
        columns: [
          {dataIndex: "c_date", key: "c_date", title: "日期", align: "center"},
          {dataIndex: "temp", key: "temp", title: "温度", align: "center", scopedSlots: { customRender: 'temp' },},
          {dataIndex: "hum", key: "hum",  title: "湿度", align: "center", scopedSlots: { customRender: 'hum' }},
        ],
        tableData: null,
        expandedRowKeys: [],

        tableIndex: '1',

        clickTemp: 0,
        clickHum: 0,
        showTempChart: false,
        showHumChart: false,
        chartData: [],

        defaultCurrent: 1,
        pageSize: 10,
        total: 0,
        tabularClass: '',
        tabularIndex: ''
      }
    },
    computed: {
      ...mapState('gatewayManagement', ['sensor_id', 'sensor_show_id','sensor_state','sensor_name','sensorDetails','detailsChart', 'detailsChart'])
    },
    watch: {
      'sensor_id': function (newValue) {
        if(newValue) {
          this.date = null
          this.defaultCurrent = 1
          this.total = 0
          this.httpSensorDetails()
          // this.time = moment(new Date()).format('MM月DD日 HH:mm:ss')
          this.expandedRowKeys = []
          this.showTempChart = false
          this.showHumChart = false
        } else {
          this.tableData = []
          this.temp = null
          this.hum = null
          this.time = null
          this.total = 0
        }
      },
      'sensorDetails': function (newValue){
        this.tableData = newValue.list.map((item,index) => {
          return {
            ...item,
            index,
            c_date: moment(item.c_date).format('MM月DD日') === moment(new Date()).format('MM月DD日') ? "今天" : (moment(item.c_date).format('MM月DD日') === moment(new Date().setDate(new Date().getDate() -1)).format('MM月DD日') ? "昨天" : moment(item.c_date).format('MM月DD日')),
            key: item.c_date,
            clickTemp: false,
            clickHum: false
          }
        })
        this.temp = newValue.temp
        this.hum = newValue.hum
        // this.time = moment(new Date()).format('MM月DD日 HH:mm:ss')
        this.time = newValue.time
        this.total = newValue.total
      },
      'detailsChart': function (newValue){
        this.chartData = newValue.map(item => {
          return { ...item}
        })
      }
    },
    methods: {
      ...mapActions('gatewayManagement', ['getSensorDetails','getDetailsChart']),
      handleEdit(sensor_id,sensor_name){
        this.showEdit = true
        this.item = {sensor_id, sensor_name}
      },
      handleWarning(sensor_id, sensor_name) {
        this.showWarning = true
        this.item = { sensor_id, sensor_name }
      },
      handleChange() {
        this.defaultCurrent = 1;
        this.httpSensorDetails()
      },
      httpSensorDetails(){
        let payload = {
          id: this.sensor_id,
          date: this.date?moment(this.date).format('YYYY-MM-DD'):'',
          limit: this.pageSize,
          offset: (this.defaultCurrent - 1) * this.pageSize
        }
        this.getSensorDetails(payload)
      },
      handleTemp(record){
        // debugger
        if (record.clickTemp){
          record.clickTemp = false
          record.clickHum = false
          this.expandedRowKeys = []
          this.showTempChart = false
          this.showHumChart = false
        }else {
          this.tableRoll()
          this.tableData = this.tableData.map((item) => {
            if (item === record){
              return {...item, clickTemp: true, clickHum: false }
            }
            // else {
            //   return {...item, clickTemp: false, clickHum: false}
            // }
            return  {...item, clickTemp: false, clickHum: false}
          })
          this.showHumChart = false
          this.showTempChart = false
          this.expandedRowKeys = [record.key]
          let payload = {
            id: this.sensor_id,
            type: '1',
            date: moment(record.key).format('YYYY-MM-DD')
          }
          this.getDetailsChart(payload).then(res => {
            this.showTempChart = true
          })
        }
      },
      handleHum(record){
        // debugger
        if (record.clickHum){
          record.clickTemp = false
          record.clickHum = false
          this.expandedRowKeys = []
          this.showTempChart = false
          this.showHumChart = false
        }else {
          this.tableRoll()
          this.tableData = this.tableData.map((item) => {
            if (item === record){
              return {...item,clickTemp: false, clickHum: true }
            }
            // else {
            //   return {...item, clickTemp: false, clickHum: false}
            // }
            return  {...item, clickTemp: false, clickHum: false}
          })
          this.showTempChart = false
          this.showHumChart = false
          this.expandedRowKeys = [record.key]

          let payload = {
            id: this.sensor_id,
            type: '2',
            date: moment(record.key).format('YYYY-MM-DD')
          }
          this.getDetailsChart(payload).then(res => {
            this.showHumChart = true
          })
        }
      },
      onChange(defaultCurrent) {
        this.defaultCurrent = defaultCurrent;
        this.httpSensorDetails()
      },
      customRow(record){
        return {
          on:{
            click: (index) => {
              this.tabularIndex = record.index
            }
          }
        }
      },
      tableRoll(){
        this.tabularClass = document.getElementsByClassName('ant-table-body')[0]
        setTimeout(() => {
          if(this.tabularClass.scrollHeight > this.tabularClass.clientHeight){
            if(this.tabularIndex === 6){
              this.tabularClass.scrollTop = 38
            } else if (this.tabularIndex === 7){
              this.tabularClass.scrollTop = 76
            } else if (this.tabularIndex === 8){
              this.tabularClass.scrollTop = 114
            } else if (this.tabularIndex === 9){
              this.tabularClass.scrollTop = 152
            }
          }
        }, 100)
      }
    }
  }
</script>

<style lang="less">
  .hum-particulars {
    border-left: 1px solid #225461;
    height: 100%;

    .stern {
      padding: 0 16px 0 17px;
    }

    .ant-table-content {
      height: 408px;
    }

    .date {
      margin: 16px 0 12px 0;
      text-align: right;
    }

    .data2 {
      height: 21px;
    }

    .img {
      width: 12px;
      height: 12px;
      margin-left: 8px;
      margin-bottom: 2px;
    }

    .lastImg {
      width: 12px;
      height: 12px;
      margin-left: 8px;
      margin-bottom: 2px;
    }
  }
</style>