<template>
  <div class="gateway">
    <div class="header">
      <top-header></top-header>
    </div>
    <div class="subject">
      <a-row>
        <a-col :span="20">
          <a-space :size="8">
            <div class="vertical_line"></div>
            <span class="title">网关管理</span>
          </a-space>
        </a-col>
        <a-col style="float:right">
          <a-space :size="12">
            <a-button class="button" type="ghost" @click="handleGroup">分组管理</a-button>
            <a-button class="button" type="primary" style="color: #020511" @click="handleAdd">添加网关</a-button>
          </a-space>
        </a-col>
      </a-row>
      <full-container>
        <card-container>
          <card-item>
            <div style="display: flex">
              <span style="width: 20%;">
                <gateway-list :idGroup="group" :idGateway="gateway"></gateway-list>
              </span>
              <span style="width: 29%;">
                <gateway-particulars :ipSensor="id"></gateway-particulars>
              </span>
              <span style="width: 51%;">
                <hum-particulars></hum-particulars>
              </span>
            </div>
          </card-item>
        </card-container>
      </full-container>
    </div>
    <drawer-group-management v-model="showGroup"></drawer-group-management>
    <model-gateway-add v-model="showAdd"></model-gateway-add>
  </div>
</template>

<script>
import GatewayList from "./components/GatewayList.vue";
import GatewayParticulars from "./components/GatewayParticulars.vue";
import HumParticulars from "./components/HumParticulars.vue";
import DrawerGroupManagement from "@/components/Common/DrawerGroupManagement.vue";
import ModelGatewayAdd from "@/components/Common/ModelGatewayAdd";
import TopHeader from "./components/TopHeader";
export default {
  name: "index",
  components: {TopHeader, ModelGatewayAdd, DrawerGroupManagement, HumParticulars, GatewayParticulars, GatewayList},
  data(){
    return{
      showGroup: false,
      showAdd: false,
      id: '',
      gateway: '',
      group: '',
    }
  },
  methods: {
    handleGroup(){
      this.showGroup = true
    },
    handleAdd(){
      this.showAdd = true
    }
  },
  created() {
    this.id = this.$route.query.id
    this.group = this.$route.query.group
    this.gateway = this.$route.query.gateway_id
  }
}
</script>

<style lang="less">
  .gateway{
    height: 100%;
    width: 100%;
    border-image: url("~@/assets/imgs/bj_img@2x.png") 100 fill;

    .header {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: #000000;
    }

    .subject {
      padding: 16px 20px 20px 20px;
      overflow: hidden;
    }

    .vertical_line {
      width: 3px;
      height: 20px;
      background-color: #66FFFF;
    }

    .title {
      font-size: 18px;
    }

    .button {
      border: 1px solid #337587;
      width: 88px;
      height: 32px;
    }
  }
</style>