<template>
  <div :id="id" class="chart"></div>
</template>

<script>
  import moment from "moment";
  export default {
    name: "model-hum-chart",
    props: ['id','item'],
    data() {
      return {
        chart: null
      }
    },
    destroyed() {
      this.chart && this.chart.dispose()
    },
    mounted() {
      this.drawChart()
      let that = this
      window.onresize = function () {
        that.resetPosition()
      }
    },
    methods: {
      findTimeVal(time){
        for(let i=0; i<this.item.length; i++){
          let ts = new Date(this.item[i].time).getTime();
          if(ts === time){
            return this.item[i].value
          }
        }
        return '-'
      },
      formatData(data) {
        // console.log('data', data)
        if (data.length === 0) {
          return data
        }
        let start = data[0]
        console.log('start', start)
        let endD = data[data.length-1]
        let startTime = new Date(start.time).getTime()
        console.log('start2', startTime)
        let endTime = new Date(endD.time).getTime();
        let newData = [];
        while(startTime <= endTime){
          let value = this.findTimeVal(startTime);
          let time = moment(startTime).format('YYYY-MM-DD HH:mm:ss');
          newData.push({time:time, value:value});
          startTime += 5*60000;
        }
        // console.log('newData', newData)
        return newData
      },
      drawChart() {
        let itemData = this.formatData(this.item);
        this.$nextTick(() => {
          let chartDom = document.getElementById(this.id);
          let option;
          if(this.chart){ //更新数据
            option = {
              xAxis: {
                data: itemData.map(item => {return item.time}),
              },
              series: [
                {
                  data: itemData.map(item => {return item.value}),
                }
              ],
            };
            this.chart.setOption(option);
            this.chart.resize()
          }else { //初始化
            this.chart = this.$echarts.init(chartDom);
            const data = itemData.map(item => {
              return [item.time, item.value]
            })
            option = {
              xAxis: {
                min: function (value) {
                  console.log('min_value === ', value)
                  const date = new Date(value.min)
                  const year = date.getFullYear()
                  const month = date.getMonth()+1
                  const day = date.getDate()
                  console.log('min_time', new Date(`${year}-${month}-${day} 00:00:00`).getTime())
                  return new Date(`${year}-${month}-${day} 00:00:00`).getTime()
                },
                max: function (value) {
                  const date = new Date(value.min)
                  const year = date.getFullYear()
                  const month = date.getMonth()+1
                  const day = date.getDate()
                  return new Date(`${year}-${month}-${day} 00:00:00`).getTime()+24*3600*1000
                },
                type: 'time',
                maxInterval: 2 * 3600 * 1000,
                axisTick:{
                  show: false,
                },
                axisLabel: {
                  formatter: function (value,index){
                    const hour = new Date(value).getHours()
                    if (hour % 6 === 0){
                      return '{HH}:{mm}'
                    }
                    return false
                  },
                  color: '#B4CDD4',
                  showMinLabel: true,
                  showMaxLabel: true,
                },
                data: itemData.map(item => {return item.time}),
                axisLine: {
                  lineStyle: {
                    color: '#225461'
                  }
                },
              },

              yAxis: {
                type: 'value',
                axisLabel: {
                  formatter: '{value}%',
                  color: '#B4CDD4',
                },

                lineStyle: {
                  color: '#225461'
                },

                splitLine: {
                  lineStyle: {
                    type: 'dashed',
                    color: '#225461',
                  }
                }
              },

              grid: {
                top: '5%',
                left: '7%',
                width: '90.5%',
                height: '80%',
              },

              series: [
                {
                  symbol: 'none',
                  smooth: true,
                  connectNulls: false,
                  data,
                  type: 'line',
                  areaStyle: {
                    color: {
                      type: 'linear',
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [
                        {
                          offset: 0,
                          color: '#389093'
                        },
                        {
                          offset: 1,
                          color: '#0B262E'
                        }
                      ]
                    }
                  },
                  lineStyle: {
                    color: '#66FFFF',
                    width: 2
                  }
                }
              ],

              tooltip: {
                formatter: function (param) {
                  let time = moment(param[0].data[0]).format('HH:mm')
                  return '<div style=""> ' + `${time}` + "<br>" +'湿度：'+ param[0].data[1] +'%'+ "<br>"+'</div>';
                },
                showTitle: false,
                trigger: 'axis',
                config: true,
                backgroundColor: '#337587',
                borderColor: 'transparent',
                axisPointer: {
                  type: 'line',
                  lineStyle: {
                    color: '#66FFFF',
                    type: 'line'
                  },
                  label: {
                    margin: 3
                  }
                },
                position: function (point, params, dom, rect, size) {
                  let x = 0;
                  let y = 0;

                  let pointX = point[0];
                  let pointY = point[1];

                  let boxWidth = size.contentSize[0];
                  let boxHeight = size.contentSize[1];

                  if (boxWidth > pointX) {
                    x = pointX + 8;
                    y -= 0;
                  } else if(pointX > 480) {
                    x = pointX - boxWidth - 8
                  } else {
                    x = pointX + boxWidth - 83;
                  }


                  if (boxHeight > pointY) {
                    y = pointY + 6;
                  } else if (pointY > boxHeight) {
                    y = pointY - boxHeight - 5;
                  }
                  return[x, y];
                },
                point: [20,40],
                textStyle: {
                  color: '#E1EEF2',
                },
              }
            };
            this.chart.setOption(option);
          }
        })
      },
      resetPosition() {
        this.chart && this.chart.resize()
      }
    }
  }
</script>

<style lang="less">
  .chart {
    flex: 1;
    height: 128px;

    & > div:first-child {
      width: 100% !important;
    }

    & > div:first-child > canvas {
      width: 100% !important;
    }
  }

</style>