<template>
  <header class="top_header">
    <div class="left">
      <a-button type="link" class="button" @click="handleHome">
        <img alt="text" class="img" src="../../../assets/imgs/youjt_icom@2x.png" />
        返回首页
      </a-button>
    </div>
    <div class="middle">
      <span class="title">生产环境监测</span>
    </div>
    <div class="right">
      <span class="text">网关管理</span>
    </div>
  </header>
</template>

<script>
  export default {
    name: "top-header",
    methods:{
      handleHome(){
        this.$router.push({
          path: "/home",
        })
      }
    },
  };
</script>

<style lang="less">
  .top_header {
    position: relative;
    display: flex;
    height: 62px;
    padding: 0;
    margin: 0;
    background-image: url("../../../assets/imgs/daoh_img.png");
    background-size: 100% 100%;

    .left {
      color: #E1EEF2;
      height: 51px;
      width: 430px;
      display: flex;
      align-items: center;
    }

    .button {
      margin-left: 2px;
      border: none!important;
      color: #E1EEF2;
      font-size: 14px;
    }

    .img {
      width: 18px;
      height: 18px;
      margin-bottom: 2px;
    }

    .middle {
      flex: 1;
      text-align: center;
      line-height: 54px;
    }

    .title {
      color: #E1EEF2;
      font-size: 24px!important;
      font-weight: bold;
    }

    .right {
      height: 51px;
      width: 430px;
      margin: 0;
      padding-top: 8px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .text {
      width: 72px;
      height: 28px;
      background: #0E525B;
      border-radius: 2px;
      text-align: center;
      line-height: 28px;
      color: #66ffff;
      font-size: 14px;
      margin-right: 19px;
    }
  }
</style>