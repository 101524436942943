<template>
  <m-modal v-model="visible" title="添加网关" :width="360"
           :confirmLoading="okButton" ok-text="保存" @ok="handleOk">
    <template slot="content">
      <m-modal-form class="form" ref="form" :model="form" :rules="rules">
        <span>网关序列号</span>
        <a-form-model-item prop="id">
          <m-input :maxLength="30" :allow-clear="true" v-model="form.id" allowClear autocomplete="false" placeholder="填写网关序列号"></m-input>
        </a-form-model-item>
        <span>网关名称</span>
        <a-form-model-item prop="name">
          <m-input :maxLength="30" :allow-clear="true" v-model="form.name" allowClear autocplete="false" placeholder="填写网关名称"></m-input>
        </a-form-model-item>
        <span>所属分组</span>
        <a-form-model-item prop="group_id">
          <m-select v-model="form.group_id" placeholder="选择分组">
            <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.id">{{ item.name }}</a-select-option>
          </m-select>
        </a-form-model-item>
      </m-modal-form>
    </template>
  </m-modal>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  export default {
    name: "model-gateway-add",
    props: {
      value: Boolean,
    },
    data(){
      return {
        okButton: false,
        form: {
          id: '',
          name: '',
          group_id: 1
        },
        rules: {
          id: [
            { required: true, message: '网关序列号不能为空', trigger: 'blur'},
            { required: true, min: 1, max: 26, message: '网关序列号的长度1到26个字符', trigger: 'blur' }
            // { required: true, min: 1, max: 16, message: '网关序列号的长度在1到16个字符', trigger: 'blur' }
            // { pattern: /^((2[0-4]\d|25[0-5]|[01]?\d\d?)\.){3}(2[0-4]\d|25[0-5]|[01]?\d\d?)$/, message: '序列号格式错误', trigger: 'blur'},
          ],
          name: [
            { required: true, message: '网关名称不能为空', trigger: 'blur' },
            { required: true, min: 1, max: 13, message: '网关名称的长度1到13个字符', trigger: 'blur' }
          ],
          group_id: [
            { required: true, message: '分组不能为空', trigger: 'blur' },
          ],
        },
        list: []
      }
    },
    computed: {
      ...mapState('groupManagement', ['groupList']),
      ...mapState('gatewayManagement',['select_id']),
      visible: {
        get() {
          return this.value
        },
        set (value) {
          this.$emit('input',value)
        }
      }
    },
    watch: {
      visible(newValue){
        newValue && this.reset()
      },
    },
    methods: {
      ...mapActions("gatewayManagement",['getGatewayList','postGatewayAdd']),
      reset(){
        this.okButton = false
        this.form = {
          id: '',
          name: '',
          group_id: 1,
        }
      },
      handleOk(){
        this.$refs.form.validate((valid) => {
          if(valid) {
            let payload = {
              id: this.form.id,
              name: this.form.name,
              group_id: this.form.group_id
            }
            this.postGatewayAdd(payload).then(res => {
              let payload = {
                group_id: this.select_id,
                limit: '10',
              }
              this.getGatewayList(payload)
              this.visible = false
            })
          }
        })
      },
    }
  }
</script>

<style lang="less">
  .form {

  }
</style>