<template>
  <m-modal v-model="visible" title="删除网关" :width="360" :height="168"
           :confirmLoading="okButton" ok-text="删除" :ok-button-props="{props: {type: 'danger',}}" @ok="handleOk">
    <template slot="content">
      <span>
        确定删除网关吗？
      </span>
    </template>
  </m-modal>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  export default {
    name: "model-gateway-delete",
    data(){
      return {
        okButton: false,
        list: []
      }
    },
    props: {
      value: Boolean,
      item: Object,
    },
    computed: {
      ...mapState('gatewayManagement',['select_id']),
      visible: {
        get() {
          return this.value
        },
        set (value) {
          this.$emit('input',value)
        }
      }
    },
    watch: {
      visible(newValue){
        newValue && this.reset()
      },
    },
    methods: {
      ...mapActions('gatewayManagement', ['deleteGateway','getGatewayList']),
      reset(){
        this.okButton = false
        this.list = { ...this.item}
      },
      handleOk(){
        let payload = {id: this.list.gateway_id}
        this.deleteGateway(payload).then(res => {
          let payload = {
            group_id: this.select_id,
            limit: '10',
          }
          this.getGatewayList(payload)
          this.visible = false
        })
      }
    }
  }
</script>

<style scoped>

</style>