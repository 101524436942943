<template>
  <m-modal v-model="visible" title="修改传感器名称" :width="360"
           :confirmLoading="okButton" ok-text="保存" @ok="handleOk">
    <template slot="content">
      <m-modal-form ref="form" :model="form" :rules="rules">
        <span>传感器名称</span>
        <a-form-model-item prop="sensor_name">
          <a-input :maxLength="30" :allow-clear="true" v-model="form.sensor_name"></a-input>
        </a-form-model-item>
      </m-modal-form>
    </template>
  </m-modal>
</template>

<script>
  import {EventBus} from "@/utils/event-bus";
  import { mapActions, mapState } from "vuex";
  export default {
    name: "model-sensor-edit",
    data(){
      return {
        okButton: false,
        form: {
          sensor_name: '',
        },
        rules: {
          sensor_name: [
            { required: true, message: '传感器名称不能为空', trigger: 'blur' },
            { required: true, min: 1, max: 16, message: '传感器名称的长度1到16个字符', trigger: 'blur' }
          ],
        }
      }
    },
    props: {
      value: Boolean,
      item: Object,
    },
    computed: {
      ...mapState('gatewayManagement',['sensor_name']),
      visible: {
        get() {
          return this.value
        },
        set (value) {
          this.$emit('input',value)
        }
      }
    },
    watch: {
      visible(newValue){
        newValue && this.reset()
      },
    },
    methods: {
      ...mapActions('gatewayManagement',['putSensorEdit']),
      reset(){
        this.okButton = false
        this.form = { ...this.item}
      },
      handleOk() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            let payload = {
              id: this.form.sensor_id,
              name: this.form.sensor_name
            }
            this.putSensorEdit(payload).then(res => {
              EventBus.$emit('updateSensorList')
              // this.$store.commit('gatewayManagement/updateState', {sensor_name: this.form.sensor_name})
              this.visible = false
            })
          }
        })
      }
    }
  }
</script>

<style lang="less">
  .ant-input {
    border: 1px solid #337587;
    background-color: transparent;
  }
</style>