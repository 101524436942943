<template>
  <m-drawer
      v-model="visible"
      title="分组管理"
      :width="600"
      @close="onClose"
      :confirmLoading="okButton"
  >
    <template #content>
      <a-row class="groupList" :gutter="[12, 12]">
        <a-col v-for="(item, index) in list" :key="index" :span="12">
          <m-input
              :ref="'input'+index"
              v-model="item.name"
              class="item"
              :allow-clear="false"
              placeholder="输入分组名称"
              :readOnly="!item.isEdit"
              :maxLength="10"
          >
            <img slot="prefix" alt="text" class="input-img" src="../../assets/imgs/fenz_icon@2x.png"/>
            <template v-if="index !== 1" slot="suffix">
              <template v-if="item.button === '0'">
                <a-button type="link" class="button" @click="handleAdd(index, item)">保存</a-button>
              </template>
              <template v-else-if="item.button === '1'">
                <img alt="text" class="edt-img" src="../../assets/imgs/bianj_icon@2x.png" @click="handleEdit(index, item)" />
                <img alt="text" class="del-img" src="../../assets/imgs/del_icon@2x.png" @click="handleDelete(index, item)" />
              </template>
              <template v-else-if="item.button === '2'">
                <a-button type="link" class="buttonAbolish" @click="onAbolish(index, item)">取消</a-button>
                <a-button type="link" class="button" @click="onEdit(index, item)">保存</a-button>
              </template>
              <template v-else-if="item.button === '3'">
                <a-button type="link" class="buttonAbolish" @click="onAbolish(index, item)">取消</a-button>
                <a-button type="link" class="buttonDelete" @click="onDelete(index, item)">删除</a-button>
              </template>
            </template>
          </m-input>
        </a-col>
      </a-row>
    </template>
  </m-drawer>
</template>

<script>
  import {EventBus} from "@/utils/event-bus";
  import { mapActions, mapState } from 'vuex';
  export default {
    name: "drawer-group-management",
    data(){
      return {
        okButton: false,
        list:[
          { name: '', isEdit: true, button: '0',},
        ]
      }
    },
    props: {
      value: Boolean,
    },
    computed: {
      ...mapState('groupManagement', ['groupList']),
      ...mapState('gatewayManagement', ['select_id','group_id']),
      visible: {
        get() {
          return this.value
        },
        set (value) {
          this.$emit('input',value)
        }
      },
    },
    mounted() {
      this.getGroupList()
    },
    watch: {
      visible(newValue){
        newValue && this.reset()
      },
      'groupList': function (newValue){
        if(newValue) {
          this.list = [
            {name: '', isEdit: true, button: '0'},
          ].concat(newValue.map((item) => ({ ...item, isEdit: false, button: '1' })))
        }
      }
    },
    methods: {
      ...mapActions('groupManagement', ['postGroupAdd','putGroupEdit','deleteGroup','getGroupList']),
      ...mapState('gatewayManagement',['getGatewayList']),
      reset(){
        this.okButton = false
        this.list= [ { name: '', isEdit: true, button: '0',}]
        this.getGroupList()
      },
      onClose() {
        this.visible = false
        this.okButton = false
      },
      handleAdd(index, item){
        let payload = { name: item.name }
        this.postGroupAdd(payload).then(res => {
          this.getGroupList()
        })
      },
      handleEdit(index, item){
        item.button = '2'
        item.isEdit = true
        this.$refs['input'+index][0].focus()
      },
      onAbolish(index, item){
        item.button = '1'
        item.isEdit = false
        this.getGroupList()
      },
      onEdit(index, item){
        let payload = {
          id: item.id,
          name: item.name
        }
        this.putGroupEdit(payload).then(res => {
          if(payload.id === this.group_id) {
            this.$store.commit('gatewayManagement/updateState', {group_name: payload.name})
          }
          this.getGroupList()
        })
      },
      handleDelete(index, item) {
        item.button = '3'
      },
      onDelete(index, item){
        let payload = { id: item.id }
        this.deleteGroup(payload).then(res => {
          EventBus.$emit('updateGatewayList')
          this.getGroupList()
        })
      },
    }
  }
</script>

<style lang="less">
  .groupList {
    .item {
      height: 48px;
      background-color: #154448;
      border-radius: 2px;
    }

    .ant-input {
      height: 48px;
    }

    .input-img {
      width: 14px;
      height: 14px;
    }

    .button {
      color: #66ffff;
    }

    .buttonAbolish {
      color: #B4CDD4;
      margin-right: 12px;
    }

    .buttonDelete {
      color: red;
    }

    .edt-img {
      margin-right: 12px;
      width: 14px;
      height: 14px;
      cursor: pointer;
    }

    .del-img {
      width: 14px;
      height: 14px;
      cursor: pointer;
    }
  }
</style>