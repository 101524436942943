<template>
  <m-modal v-model="visible" title="修改网关" :width="360"
         :cofirmLoading="okButton" ok-text="保存" @ok="handleOk">
    <template slot="content">
      <m-modal-form  ref="groupForm" :model="groupForm" :rules="rules">
        <span>网关序列号</span>
        <a-form-model-item>
          <a-input :allow-clear="false" v-model="groupForm.gateway_id" disabled></a-input>
        </a-form-model-item>
        <span>网关名称</span>
        <a-form-model-item prop="gateway_name">
          <a-input :maxLength="30" :allow-clear="true" v-model="groupForm.gateway_name"></a-input>
        </a-form-model-item>
        <span>所属分组</span>
        <a-form-model-item>
          <m-select v-model="groupForm.group_id" >
            <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.id">{{item.name}}</a-select-option>
          </m-select>
        </a-form-model-item>
      </m-modal-form>
    </template>
  </m-modal>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  export default {
    name: "model-gateway-edit",
    data(){
      return {
        okButton: false,
        groupForm: {
          gateway_id: '',
          gateway_name: '',
          group_id: ''
        },
        rules: {
          gateway_name: [
            { required: true, message: '网关名称不能为空', trigger: 'blur' },
            { required: true, min: 1, max: 13, message: '网关名称的长度1到13个字符', trigger: 'blur' }
          ],
        },
        list: []
      }
    },
    props: {
      value: Boolean,
      item: Object,
    },
    computed: {
      ...mapState('groupManagement', ['groupList']),
      ...mapState('gatewayManagement',['select_id']),
      visible: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input',value)
        }
      }
    },
    watch: {
      visible(newValue){
        newValue && this.reset()
      },
    },
    methods: {
      ...mapActions('gatewayManagement', ['putGatewayEdit','getGatewayList']),
      reset(){
        this.okButton = false
        this.groupForm = {...this.item}
      },

      handleOk() {
        this.$refs.groupForm.validate((valid) => {
          if (valid) {
            let payload = {
              id: this.groupForm.gateway_id,
              name: this.groupForm.gateway_name,
              group_id: this.groupForm.group_id
            }
            this.putGatewayEdit(payload).then(res => {
              if(this.select_id) {
                let payload = {
                  group_id: this.select_id,
                  limit: '10',
                }
                this.getGatewayList(payload)
              }else {
                this.getGatewayList()
              }
              this.visible = false
            })
          }
        })
      },
    }
  }
</script>

<style lang="less">
  .ant-input {
    border: 1px solid #337587;
    background-color: transparent;
  }
</style>