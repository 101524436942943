<template>
  <div class="gateway-particulars">
    <div v-if="gateway_id !== ''">
      <div class="head">
        <a-space :size="8">
          <div :class="gateway_state === 1 ?'stateOn':'stateOff'">{{ gateway_state === 1 ? '在线':'离线'}}</div>
          <span class="name">{{ gateway_name }}</span>
        </a-space>
        <div class="dropdown">
          <a-dropdown :placement="placement" overlayClassName="overlayClass" :open="true">
            <a @click="e => e.preventDefault()">
              <img alt="text" class="dropdown-img" src="../../../assets/imgs/gengd_icon@2x.png" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item key="gatewayEdit" @click="handleEdit(gateway_id, gateway_name, group_id)">
                <a>修改网关</a>
              </a-menu-item>
              <a-menu-item key="gatewayDelete" @click="handleDelete(gateway_id)">
                <a>删除网关</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </div>
      <div class="news">
        <span class="text">序列号:</span>
        <span class="data" :title="gateway_id">{{ gateway_id }}</span>
        <div class="circle"></div>
        <span class="text">所属分组:</span>
        <span class="data" :title="group_name">{{ group_name }}</span>
      </div>
      <div class="striping"></div>
      <div class="center_section">
        <a-row>
          <a-col :span="10">
            <span class="small_title">温湿度传感器</span>
          </a-col>
          <a-col style="float:right">
            <m-input type="text" style="width: 178px;" v-model="search" placeholder="名称或编码" >
              <img slot="prefix" alt="text" class="input-img" src="../../../assets/imgs/sous_icon@2x.png" @click="httpSensorList" />
            </m-input>
          </a-col>
        </a-row>
      </div>
      <div class="list">
        <div v-if="sensorForm.length > 0">
          <template>
            <a-list
                item-layout="horizontal"
                :split="false"
                :data-source="sensorForm"
                :pagination="false"
            >
              <a-list-item
                  slot="renderItem"
                  slot-scope="item,index"
                  :class="{highlight: itemIndex === index }"
                  @click="handleItem(item, index)"
              >
                <a-list-item-meta :description="'地址：'+item.show_id">
                  <a slot="title">{{ item.name }}</a>
                  <div slot="avatar" style="position: relative;">
                    <img alt="text" class="img" src="../../../assets/imgs/chuangq_img@2x.png" />
                    <div :class="item.state === 1 ?'online':'offline'">{{ item.state === 1 ? '在线':'离线' }}</div>
                  </div>
                </a-list-item-meta>
              </a-list-item>
            </a-list>
            <m-pagination :hideOnSinglePage="true" :defaultCurrent="defaultCurrent" :pageSize="pageSize" :total="total" @change="onChange"></m-pagination>
          </template>
        </div>
        <div v-else>
          <empty-content>
            <template slot="img">
              <img class="listImage" alt="text" src="../../../assets/imgs/wusj_img@2x.png" />
            </template>
            <template slot="desc">
              <span>暂无温湿度传感器</span>
            </template>
          </empty-content>
        </div>
      </div>
    </div>
    <div v-else>
      <empty-content>
        <template slot="img">
          <img class="backgroundImage" alt="text" src="../../../assets/imgs/wusj_img@2x.png" />
        </template>
        <template slot="desc">
          <span>暂无温湿度传感器</span>
        </template>
      </empty-content>
    </div>
    <model-gateway-edit v-model="showEdit" :item="item"></model-gateway-edit>
    <model-gateway-delete v-model="showDelete" :item="item"></model-gateway-delete>
  </div>
</template>

<script>
  import {EventBus} from "@/utils/event-bus";
  import { mapActions, mapState } from "vuex";
  import ModelGatewayEdit from "@/components/Common/ModelGatewayEdit";
  import ModelGatewayDelete from "@/components/Common/ModelGatewayDelete";
  export default {
    name: "gateway-particulars",
    components: { ModelGatewayEdit, ModelGatewayDelete },
    data() {
      return {
        search: '',
        placement: 'bottomRight',

        showEdit: false,
        showDelete: false,
        item: null,
        sensorForm: [],

        screenWidth: '',

        itemIndex: 0,

        defaultCurrent: 1,
        pageSize: 8,
        total: 0,
        isFind: false
      }
    },
    props: {
      ipSensor: String,
      required: true,
    },
    computed: {
      ...mapState('gatewayManagement', [ 'group_name','group_id','gateway_id','gateway_name','gateway_state','sensorList', 'sensor_name', 'sensor_id']),
    },
    mounted() {
      EventBus.$on('updateSensorList',  () =>{
        let payload = {
            gateway_id: this.gateway_id,
            search: this.search,
            limit: this.pageSize,
            offset: (this.defaultCurrent - 1) * this.pageSize
        }
        this.getSensorList(payload)
      })
    },
    beforeDestroy() {
      EventBus.$off('updateSensorList')
    },
    watch: {
      'gateway_id': function (newValue){
        if (newValue) {
          this.search = ''
          this.defaultCurrent = 1
          this.httpSensorList()
        }else {
          this.sensorForm = []
          this.$store.commit('gatewayManagement/updateState', {sensor_id: ''})
          this.$store.commit('gatewayManagement/updateState', {sensor_show_id: ''})
          this.$store.commit('gatewayManagement/updateState', {sensor_name: ''})
          this.$store.commit('gatewayManagement/updateState', {sensor_state: ''})
        }
      },
      'sensorList': function (newValue){
        this.total = newValue.total
        if (newValue) {
          if(this.ipSensor && !this.isFind) {
            this.sensorForm = newValue.list
            let flag = 0
            for(let i=0; i < this.sensorForm.length; i++){
              if(this.sensorForm[i].id === this.ipSensor){
                flag = 1
                this.isFind = true
                this.handleItem(this.sensorForm[i], i)
                this.$store.commit('gatewayManagement/updateState', {sensor_show_id: this.sensorForm[i].show_id})
              }
            }
            if (flag === 0) {
              this.defaultCurrent += 1
              this.findSensorItem(this.ipSensor, this.defaultCurrent)
              return
            }
            this.$store.commit('gatewayManagement/updateState', {sensor_id: this.ipSensor})
          }else if (newValue.list.length) {
            this.sensorForm = newValue.list
            this.handleItem(this.sensorForm[0], 0)
          }else {
            this.sensorForm = []
            this.$store.commit('gatewayManagement/updateState', {sensor_id: ''})
            this.$store.commit('gatewayManagement/updateState', {sensor_show_id: ''})
            this.$store.commit('gatewayManagement/updateState', {sensor_name: ''})
            this.$store.commit('gatewayManagement/updateState', {sensor_state: ''})
          }
        }
      },
    },
    methods: {
      ...mapActions('gatewayManagement', ['getSensorList']),
      handleEdit(gateway_id, gateway_name, group_id){
        this.showEdit = true
        this.item = {gateway_id, gateway_name, group_id}
      },
      handleDelete(gateway_id) {
        this.showDelete = true
        this.item = {gateway_id}
      },
      httpSensorList(){
        let payload = {
          gateway_id: this.gateway_id,
          search: this.search,
          limit: this.pageSize,
          offset: (this.defaultCurrent - 1) * this.pageSize
        }
        this.getSensorList(payload)
      },
      findSensorItem(sensor_id, page) {
        let total_page = (this.total % this.pageSize === 0) ? this.total / this.pageSize : (parseInt(this.total / this.pageSize) + 1)
        if (page > total_page) {
          return;
        }
        let payload = {
          gateway_id: this.gateway_id,
          limit: this.pageSize,
          offset: (page-1) * this.pageSize
        }
        this.getSensorList(payload)
      },
      handleItem(item, index) {
          this.itemIndex = index
          this.search = ''
          this.$store.commit('gatewayManagement/updateState', {sensor_id: item.id})
          this.$store.commit('gatewayManagement/updateState', {sensor_show_id: item.show_id})
          this.$store.commit('gatewayManagement/updateState', {sensor_name: item.name})
          this.$store.commit('gatewayManagement/updateState', {sensor_state: item.state})
      },
      onChange(defaultCurrent) {
        this.defaultCurrent = defaultCurrent;
        this.httpSensorList()
      }
    }
  }
</script>

<style lang="less">
  .gateway-particulars {
    border-left: 1px solid #225461;
    height: 100%;

    .ant-input {
      padding: 3px 11px 4px 31px!important;
    }

    .input-img {
      width: 14px;
      height: 14px;
      cursor: pointer
    }

    .center_section {
      padding: 16px 16px 12px 0;
    }

    .small_title {
      padding: 0 16px;
      font-size: 16px;
    }

    .list {
      height: 598px;
    }

    .ant-list-items {
      height: 530px;
    }

    .highlight {
      .ant-list-item-meta {
        background: #153B42;
      }
    }

    .listImage {
      margin-top: 120px;
      width: 140px;
      height: 140px;
      fill: #658C99;
      overflow: hidden;
    }
  }

  .head {
    padding: 18px 16px 0 16px;
    height: 45px;
  }

  .name {
    font-weight: bold;
    font-size: 18px;
    height: 24px;
  }

  .news {
    display: flex;
    padding: 13px 16px 20px;
    height: 54px;
  }

  .text {
    color: #658C99;
    margin: 0 5px 5px 0;
    white-space: nowrap;
  }

  .circle{
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #658C99;
    margin: 10px 10px 0 10px;
    display: inline-block;
  }

  .data {
    height: 21px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
  }

  .dropdown {
    float:right;
  }

  .dropdown-img {
    width: 22px;
    height: 22px;
  }

  .stateOn {
    width: 30px;
    height: 18px;
    color: #020511;
    text-align: center;
    font-size: 12px;
    background-color: #6CE117;
    border-radius: 2px;
  }

  .stateOff {
    width: 30px;
    height: 18px;
    color: #020511;
    text-align: center;
    font-size: 12px;
    background-color: #B4C1D4;
    border-radius: 2px;
  }
</style>