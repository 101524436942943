<template>
  <m-drawer
    class="drawer-waring"
    v-model="visible"
    title="告警通知"
    :width="525"
    @close="onClose"
    :confirmLoading="okButton"
  >
    <template #content>
      <m-modal-form class="form" ref="warningForm" :model="warningForm" :rules="rules">
        <span>是否启用</span>
        <a-form-model-item>
          <a-radio-group v-model="warningForm.state">
            <a-radio
              v-for="items in stateRadio"
              :key="items.name"
              :value="items.value"
            >{{ items.name }}</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <span>通知条件</span>
        <a-form-model-item class="notification-condition">
          <a-form-model-item prop="condition">
            <div>
              <m-select class="type-select" v-model="warningForm.condition" placeholder="请选择">
                <a-select-option v-for="items in conditionOptions" :key="items.value" :value="items.value"
                >{{ items.name }}</a-select-option>
              </m-select>
              <div class="condition-name">数据名</div>
            </div>
          </a-form-model-item>
          <div class="relation-content">
            <m-select v-model="warningForm.sign">
              <a-select-option v-for="items in signOptions" :key="items.value" :value="items.value"
              >{{ items.name }}</a-select-option>
            </m-select>
            <div class="condition-name">关系</div>
          </div>
          <a-form-model-item prop="value">
            <div>
              <a-input class="value-input" :allow-clear="true" v-model="warningForm.value" placeholder="请填写"></a-input>
              <div class="condition-name">值</div>
            </div>
          </a-form-model-item>
        </a-form-model-item>
        <span>Webhook地址</span>
        <a-form-model-item prop="webhook">
          <a-mentions class="mentions-hook"  v-model="warningForm.webhook" placeholder="填写Webhook地址" rows="3" />
        </a-form-model-item>
        <span>添加企业微信群机器人流程</span>
        <a-form-model-item>
          <a-carousel ref="carouselRef" dot-position="top" autoplay :after-change="handleAfterChange">
            <div v-for="items in cards" :key="items.path" class="img-container">
              <img class="img" :src="items.path" alt="" />
            </div>
          </a-carousel>
          <div class="carousel-bottom">
            <div class="img-text">{{ cards[current].text }}</div>
            <div class="img-button">
              <div class="img-icon" @click="handlePrev">
               <img class="arrow-img" src="../../assets/imgs/youjt_icom@2x.png" alt="" />
              </div>
              <div class="img-icon" @click="handleNext">
                <img class="arrow-img" style="transform: scaleX(-1)" src="../../assets/imgs/youjt_icom@2x.png" alt="" />
              </div>
            </div>
          </div>
        </a-form-model-item>
      </m-modal-form>
      <div class="button-container">
        <a-button style="background-color: transparent" @click="onClose">取消</a-button>
        <a-button type="primary" @click="handleOk">保存</a-button>
      </div>
    </template>
  </m-drawer>
</template>

<script>
  import qiywxcz_o from '@/assets/imgs/qiywxcz_o_img@2x.png';
  import qiywxcz_t from '@/assets/imgs/qiywxcz_t_img@2x.png';
  import qiywxcz_th from '@/assets/imgs/qiywxcz_th_img@2x.png';
  import qiywxcz_fo from '@/assets/imgs/qiywxcz_fo_img@2x.png';
  import { mapActions, mapState } from "vuex";
  export default {
    name: 'drawer-warning',
    data() {
      return {
        okButton: false,
        warningForm: {
          state: 1,
          condition: undefined,
          sign: 1,
          value: '',
          webhook: ''
        },
        rules: {
          value: [
            { required: true, message: '请填写值', trigger: 'blur'},
            { pattern: /^(?:100|[1-9]\d|\d)$/, message: '只能填写0~100的整数', trigger: 'blur' }
          ],
          condition: [
            { required: true, message: '请选择关系', trigger: 'blur' },
          ],
          webhook: [
            { required: true, message: '请填写Webhook地址', trigger: 'blur'}
          ]
        },
        list: [],
        stateRadio: [
          { name: '启用', value: 1 },
          { name: '禁用', value: 2 }
        ],
        conditionOptions: [
          { value: 1, name: '温度' },
          { value: 2, name: '湿度' }
        ],
        signOptions: [
          { value: 1, name: '>' }
        ],
        current: 0,
        cards: [
          { path: qiywxcz_o, text: '第1步：打开电脑端企业微信' },
          { path: qiywxcz_t, text: '第2步：点击左上位置"+" 创建群聊' },
          { path: qiywxcz_th, text: '第3步：群聊页面点击右上角"..." 选择创建群机器人' },
          { path: qiywxcz_fo, text: '第4步：复制机器人Webhook地址' }
        ],
        oneImg: qiywxcz_o
      }
    },
    props: {
      value: Boolean,
    },
    computed: {
      ...mapState('gatewayManagement', ['gateway_id', 'sensor_show_id', 'notificationDetails']),
      visible: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input',value)
        }
      }
    },
    watch: {
      visible(newValue) {
        newValue && this.reset()
      }
    },
    methods: {
      ...mapActions('gatewayManagement', ['getNotificationDetails', 'putSettingNotice']),
      reset() {
        this.okButton = false
        setTimeout(() => {
          this.$refs.carouselRef.goTo(0)
          this.current = 0
        }, 50)
        let payload = { gateway_id: this.gateway_id, show_id: this.sensor_show_id }
        this.getNotificationDetails(payload).then(res => {
          if (res.code === 200) {
            const { condition } = this.notificationDetails
            this.warningForm = { ...this.notificationDetails, condition: condition ? condition : undefined }
          }
        })
      },
      handleAfterChange(value) {
        this.current = value
      },
      handleNext() {
        this.$refs.carouselRef.next()
      },
      handlePrev() {
        this.$refs.carouselRef.prev()
      },
      onClose() {
        this.visible = false
        this.okButton = false
      },
      handleOk() {
        this.$refs.warningForm.validate((valid) => {
          if(valid) {
            let payload = {
              gateway_id: this.gateway_id,
              show_id: this.sensor_show_id,
              ...this.warningForm
            }
            this.putSettingNotice(payload).then(res => {
              if (res.code === 200) {
                this.visible = false
              }
            })
          }
        })
      }
    }
  }
</script>

<style scoped lang="less">
  .drawer-waring {
    .form {
      /deep/.ant-col-8 {
        width: 100%;
      }

      .ant-radio-wrapper {
        line-height: 19px;
      }

      /deep/.ant-radio {
        padding-top: 2px;
      }

      /deep/.ant-radio-inner {
        background-color: transparent;
      }

      /deep/.ant-radio-checked::after {
        background-color: #66ffff;
      }

      .notification-condition {
        margin: 10px 0 0 0;

        /deep/.ant-form-item-children {
          display: flex;
        }

        .type-select {
          width: 120px;
        }

        .relation-content {
          width: 80px;
          margin: 0 16px;
        }

        .value-input {
          width: 120px;
        }

        .condition-name {
          color: #658C99;
          font-size: 12px;
          line-height: 16px;
        }
      }

      .mentions-hook {
        margin-top: 10px;
        background-color: transparent;
      }

      /deep/.ant-mentions > textarea {
        background-color: transparent!important;
      }

      /deep/textarea::placeholder {
        color: #658C99;
      }

      ///deep/.ant-mentions {
      //  background-color: transparent;
      //}

      .img-container {
        border-radius: 8px;

        .img {
          width: 477px;
          height: 250px;
        }
      }

      /deep/.ant-carousel {
        height: 250px;
        line-height: 1;
        margin-top: 10px;

        .slick-dots {
          display: flex !important;
          justify-content: flex-end;
          padding-right: 12px;
        }

        .slick-dots li {
          width: 8px;
        }

        .slick-dots li.slick-active {
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }

        .slick-dots li button {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #b7bbc2;
        }

        .slick-slider .slick-list{
          border-radius: 8px;
        }
      }

      /dee/.ant-checkbox-inner {
        background: transparent;
      }

      /deep/.ant-radio-wrapper {
        margin-right: 30px;
      }

      /deep/.ant-checkbox-wrapper {
        .ant-checkbox-checked {
          border-radius: 2px;
          background-color: #6c6f73;
        }
      }

      .carousel-bottom {
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 38px;
        padding: 0 16px;
        background-color: rgba(28, 54, 64, 0.45);

        .img-text {
          color: #ffffff;
        }

        .img-button {
          display: flex;
        }

        .img-icon {
          width: 28px;
          height: 28px;
          cursor: pointer;
          border-radius: 50%;
          background-color: #516C77;
          display: flex;
          padding: 7px 8px;

          .arrow-img {
            width: 12px;
            height: 14px;
          }
        }

        .img-icon + .img-icon {
          margin-left: 12px;
        }
      }
    }

    .button-container {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      padding: 24px;
      text-align: right;

      .ant-btn {
        width: 90px;
      }

      .ant-btn-primary {
        color: #020511;
      }

      .ant-btn + .ant-btn {
        margin-left: 16px;
      }
    }
  }
</style>